<template>
  <div class="container" element-loading-text="Loading" v-loading="listLoading">
    <BodyContent :pageData="pageData" @initData="initData">
      <div slot="table">
        <div class="table-body-content" style="padding: 20px 20px 10px 20px">
          <div class="table-header">
            <div class="search-box">
              <div class="search-title">报表状态</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search-box">
              <div class="search-title">责任部门</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search-box">
              <div class="search-title">指标权重</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-box">
              <div class="search-title">关键字</div>
              <el-input
                placeholder="请输入报表名称进行查询"
                clearable
                style="width: 250px; margin-right: 20px"
                v-model.trim="requestParams.keyword"
                maxlength="100"
              />
            </div>
            <el-button type="text">重置</el-button>
          </div>


          <div class="table-content">
            <el-button type="text" icon="el-icon-plus" @click="dialogFormVisible = true">新增报表</el-button>
            <el-button type="text" icon="el-icon-delete">删除报表</el-button>
            <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
              <el-table-column align="center" type="selection" width="80"></el-table-column>
              <el-table-column align="center" label="报表名称">
                <template slot-scope="scope"> <span class="text1" @click=" dialogFormVisible = true;edit = false ">新员工考核报表</span></template>
              </el-table-column>
              <el-table-column align="center" label="报表状态">
                <template slot-scope="scope">
                  <el-switch></el-switch>
                </template>
              </el-table-column>
              <el-table-column align="center" label="指标个数">
                <template slot-scope="scope">3</template>
              </el-table-column>
              <el-table-column align="center" label="指标权重">
                <template slot-scope="scope">开启</template>
              </el-table-column>
              <el-table-column align="center" label="换算系数">
                <template slot-scope="scope">--</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit-outline" @click="dialogFormVisible = true"></el-button>
                    <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                  </template>
                </el-table-column>
            </el-table>
          </div>
        </div>
        <el-dialog  :visible.sync="dialogFormVisible" width="70%">
          <div slot="title" class="dialog_title">
            {{ruleForm.id ? '编辑' : '新增'}}
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="报表名称：" prop="name" style="width:50%;display:inline-block">
                  <el-input v-if="edit" v-model="ruleForm.name" maxlength="200"></el-input>
                  <span v-else> 新员工考核报表</span>
                </el-form-item>
                <el-form-item label="报表分类：" prop="name" style="width:50%;display:inline-block">
                  <el-select v-if="edit" style="width:100%" v-model="ruleForm.name" placeholder="请选择">
                  </el-select>
                  <span v-else>员工管理</span>
                </el-form-item>
                <el-form-item label="报表状态：" style="width:50%;display:inline-block" >
                  <el-switch :disabled="!edit"></el-switch><span class="text1">已启用</span>
                </el-form-item>
                <el-form-item label="指标权重：" style="width:50%;display:inline-block" >
                  <el-switch :disabled="!edit"></el-switch> <span class="text1">指标权重之和为100%</span>
                </el-form-item>

                <el-form-item label="责任部门：" style="width:50%;display:inline-block" >
                  <el-select v-if="edit" style="width:100%" v-model="ruleForm.name" placeholder="请选择">
                  </el-select>
                  <span v-else>大数据技术中心</span>
                </el-form-item>
                <el-form-item label="指数换算系数" style="width:50%;display:inline-block" >
                  <el-switch :disabled="!edit"></el-switch><el-input-number v-if="edit" controls-position="right"></el-input-number> <span v-else>15</span>
                </el-form-item>

                <el-form-item label="计分方式：" prop="name">
                  Sum（指标分数*指标权重）  *    换算系数
                </el-form-item>

                <el-form-item label="报表说明：" prop="name">
                  <el-input v-model="ruleForm.name" type="textarea" :rows="2" placeholder="请输入报表说明" maxlength="1000"></el-input>
                </el-form-item>

                <el-form-item label="评分指标：">
                  <el-button type="text" v-if="edit" @click="innerVisible = true" icon="el-icon-plus">新增选项</el-button>
                  <el-button type="text" v-if="edit" icon="el-icon-delete">删除选项</el-button>
                  <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                    <el-table-column align="center" type="selection" width="80" v-if="edit"></el-table-column>
                    <el-table-column align="center" type="index" label="序号" width="80" v-else></el-table-column>
                    <el-table-column align="center" label="指标分类">
                      <template slot-scope="scope">
                        业绩考核
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="指标名称">
                      <template slot-scope="scope">
                        服务款项按时回收率
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="分值范围">
                      <template slot-scope="scope">
                          0~100
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="指标权重">
                      <template slot-scope="scope">
                        <el-input-number v-if="edit" controls-position="right"></el-input-number>
                        <span v-else>15</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" v-if="edit">
                      <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                
          </el-form>


          
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
          </div>

          <el-dialog
              width="50%"
              :visible.sync="innerVisible"
              append-to-body>
              <div slot="title" class="dialog_title">
                选择指标
                <el-button icon="el-icon-rank" type="text" ></el-button>
              </div>
              <el-row :gutter="20">
                <el-col :span="12" >
                  <div class="title">待选指标</div>
                  <div class="left">
                    <el-input
                      placeholder="请输入部门名称进行查询"
                      clearable
                      style="width: 100%"
                      v-model.trim="requestParams.keyword"
                    />
                    <div class="list">
                      <div v-for="(item) in 7" :key="item">
                        服务款项按时回收率
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="title">已选指标 <span style="float:right">已选总数：{{ruleForm.obj.length}}</span> </div>
                  <el-table :data="ruleForm.obj" stripe border ref="bannerTable" row-key="id" fit>
                    <el-table-column align="center" label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="指标分类">
                      <template slot-scope="scope">服务款项按时回收率</template>
                    </el-table-column>
                    <el-table-column align="center" label="指标名称">
                      <template slot-scope="scope">用户投诉追溯率</template>
                    </el-table-column>
                    
                  </el-table>
                </el-col>
              </el-row>
            </el-dialog>
        </el-dialog>
      </div>
    </BodyContent>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      edit:true,
      activeNames:'1',
      detailed:false,
      assessment:false,
      activeName:'first',
      innerVisible:false,
      dialogFormVisible:false,
      listLoading: false,
      stateList: [],
      requestParams: {
        time:'',
        state: "",
      },
      rules:{
        name:[{ required: true, message: '请填写名称' }],
        state:[{ required: true, message: '请选择状态' }],
        obj:[{ type: 'array',required: true, message: '请选择考核对象',trigger: 'change'  }],
      },
      ruleForm:{
        name:'',
        id:'',
        state:false,
        obj:[{},{}]
      },
      options: [],
      pageData: {},
      tableData:[{},{},{}]
    };
  },
  methods: {
    initData() {},
  },
};
</script>
<style lang="scss" scoped>
.text1{
  color:#5D7CE1
}
.title{
    margin-bottom: 5px;
}
.left{

  border: 1px solid #DCDFE6;
  border-radius: 4px;
  /deep/.el-input__inner{
    border: 0;
    border-bottom: 1px solid #DCDFE6;
  }
  .list{
    padding: 0 5px;
  div{
    padding: 10px;
  }
}
}

</style>
